<template>
  <div class="mcr-glossary-container">
    <mcr-glossary
      :mdContent="glossaryWikiDataState.content"
      :title="glossaryWikiDataState.title"
      :pic="glossaryWikiDataState.imgUrl"
      :wikiUrl="glossaryWikiDataState.wikiUrl"
      :loading="loading"
      :shown="shown"
      v-click-outside="hide"
      @close="hide"
    ></mcr-glossary>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import McrGlossary from './mcrGlossary';

export default {
  components: {McrGlossary},
  data() {
    return {
      loading: false,
      shown: false,
    };
  },
  watch: {
    // when set static content, first commit setGlossaryWikiDataState, then setGlossaryWikiNaturalIdState to null
    // glossaryWikiNaturalIdState watcher handles switch between 2 dynamic contents or dynamic and static
    // glossaryWikiDataState watcher handles switch between 2 static contents
    glossaryWikiNaturalIdState(value) {
      this.hideWithCallback(() => {
        if (value) {
          this.getWikiPage(value);
        } else if (this.glossaryWikiDataState.content || this.glossaryWikiDataState.title) {
          this.shown = true;
          this.loading = false;
        }
      });
    },
    glossaryWikiDataState(value) {
      if (!value.content && !value.title) {
        return;
      }
      if (this.glossaryWikiNaturalIdState) {
        return;
      }

      // re-draw in case static content switch
      this.hideWithCallback(() => {
        this.loading = false;
        this.shown = true;
      });
    },
  },
  computed: {
    ...mapGetters(['glossaryWikiDataState', 'glossaryWikiNaturalIdState']),
  },
  methods: {
    hide() {
      this.shown = false;
      this.$store.commit('setGlossaryWikiNaturalIdState', null);
      this.$store.commit('setGlossaryWikiDataState', {});
    },
    getWikiPage(value) {
      this.shown = true;
      this.loading = true;
      this.network.wiki
        .getArticleRaw({slug: value})
        .then(res => {
          this.loading = false;
          let wikiUrl = '';
          if (res.attributes) {
            for (let attribute of res.attributes) {
              if (attribute.attribute_name === 'Link') {
                wikiUrl = attribute.attribute_value;
              }
            }
          }

          this.$store.commit('setGlossaryWikiDataState', {
            title: res.title,
            content: res.content,
            imgUrl: res.image,
            wikiUrl,
          });
        })
        .catch(error => {});
    },
    hideWithCallback(callback) {
      const wasShown = this.shown;
      const timeout = wasShown ? 500 : 0; // timeout 500 to let the close animation play
      this.shown = false;
      this.loading = true;
      setTimeout(callback, timeout);
    },
  },
  name: 'mcrGlossaryContainer',
};
</script>

<style lang="scss" scoped>
.mcr-glossary-container {
  z-index: 12347; // calc(#{$modal-z-index} + 1); - calc z-index doesn't work in IE
}
</style>
