<template>
  <div id="glossary">
    <close-button @click="closeGlossary" v-if="shown" />
    <mcr-wiki
      :cclass="modifiedCclass"
      :mdContent="mdContent"
      :title="title"
      :pic="pic"
      :wikiUrl="wikiUrl"
      :loading="loading"
      class="glossary"
    />
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';

const McrWiki = () => import('./mcrWiki');

export default {
  components: {McrWiki, CloseButton},
  props: {
    cclass: String,
    mdContent: String,
    title: String,
    pic: String,
    wikiUrl: String,
    loading: Boolean,
    shown: Boolean, // only used in mcr-wiki-glossary-container, the jlt report changes `shown` with jquery.
  },
  computed: {
    modifiedCclass() {
      let cclass = `${this.cclass || ''}`;
      return this.shown ? `${cclass} shown` : cclass;
    },
  },
  methods: {
    closeGlossary() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
#glossary {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9997;
  height: 100%;

  .close-btn {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    padding: 0 12px;
    display: none;
  }

  .glossary {
    position: absolute;
    top: 40px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fafafa;
    width: 400px;
    padding: 40px 45px;
    box-shadow: -5px 7px 5px rgba(0, 0, 0, 0.07);
    max-height: calc(100% - 160px);
    overflow-y: auto;
    pointer-events: auto;
    right: -100vw;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0, 1, 1, 1);

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
    }

    &.shown {
      right: 40px;
      opacity: 1;
    }

    .img {
      img {
        width: 100%;
      }
      span {
        color: #444;
        font-size: 14px;
        font-style: italic;
        text-align: center;
        display: block;
      }
    }

    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .glossary::v-deep {
      top: 0;
      border-radius: 0;
      width: 100vw;
      box-sizing: border-box;
      border: 0;
      border-radius: 0;
      max-height: 100vh;
      &.shown {
        right: 0;
      }
    }

    .close-btn {
      display: block;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile-se) {
    .glossary::v-deep {
      padding: $mobile-se-content-padding;
      h3 {
        padding-right: 24px;
      }
    }
  }
}
</style>
